<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('log_bank.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> Bank Status </v-card-title>
        <v-card-actions>
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="bankStatus"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loadingBank" :search="search">
            <template v-slot:[`item.bank`]="{ item }">
              <img v-if="item.bank" alt="Avatar" width="25px"
                :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
            </template>
            <template v-slot:[`item.balance`]="{ item }">
              {{ item.balance | currency }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div v-if="item.status === true" class="success--text">{{ $t('log_bank.open') }}</div>
              <div v-else class="error--text">{{ $t('log_bank.close') }}</div>
            </template>
            <template v-slot:[`item.use_withdraw`]="{ item }">
              <div v-if="item.use_withdraw === false" class="success--text">{{ $t('txt_deposit') }}</div>
              <div v-else class="error--text">{{ $t('txt_withdraw') }}</div>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-divider />
    <v-col cols="12">
      <v-card>
        <v-card-title> Transactions Listing </v-card-title>
        <v-card-actions>
          <v-row no-gutters>
            <v-select v-model="bank" label="Bank" :items="banks" item-text="name" outlined dense />
            <v-select v-model="type" label="Trans type" :items="transactionType" outlined dense />
            <span>
              <date-picker-input :label="$t('log_bank.date_from')" :date="dateFrom" @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input :label="$t('log_bank.date_to')" :date="dateTo" @setValue="dateTo = $event" />
            </span>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-btn color="primary" class="" @click="findTransactionBank"> {{ $t('search') }} </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="transactionHeaders" :items="transactions"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination_transaction"
            :server-items-length="pagination_transaction.totalItems" :items-per-page="pagination_transaction.rowsPerPage"
            :page.sync="pagination_transaction.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50, 100, 200],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.bank`]="{ item }">
              <img v-if="item.bank" alt="Avatar" width="25px"
                :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
            </template>
            <template v-slot:[`item.amount`]="{ item }" class="success">
              <span :class="item.amountStatus === 'withdraw' ? 'error--text' : 'success--text'">{{ item.amount | currency
              }}</span>
            </template>
            <template v-slot:[`item.amountStatus`]="{ item }" class="success">
              <span :class="item.amountStatus === 'withdraw' ? 'error--text' : 'success--text'">{{
                item.amountStatus
              }}</span>
            </template>
            <template v-slot:[`item.timeday`]="{ item }">
              <span>{{ item.timeday }}</span>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'

export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      transactionHeaders: [
        {
          text: this.$t('log_bank.tl_header1'),
          value: 'id',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header2'),
          value: 'bank',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header3'),
          value: 'accNumber',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header4'),
          value: 'accName',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header5'),
          value: 'amountStatus',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header6'),
          value: 'amount',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header7'),
          value: 'description',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header8'),
          value: 'use_phone',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header9'),
          value: 'timeday',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_bank.tl_header10'),
          value: 'time_change_status',
          align: 'center',
          sortable: false,
        }
      ],
      headers: [
        {
          text: this.$t('log_bank.bs_header1'),
          value: 'bank',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('log_bank.bs_header2'),
          value: 'accNumber',
          width: '100px',
          sortable: false,
        },

        {
          text: this.$t('log_bank.bs_header3'),
          value: 'accName',
          sortable: false,
        },
        {
          text: this.$t('log_bank.bs_header4'),
          value: 'balance',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_bank.bs_header5'),
          value: 'status',
          sortable: false,
        },
        {
          text: this.$t('log_bank.bs_header6'),
          value: 'use_withdraw',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('log_bank.bs_header7'),
          value: 'lastSuccessfulSynced',
          align: 'center',
          sortable: false,
        },
      ],
      bankStatus: [],
      transactions: [],
      search: '',
      transactionType: [
        {
          text: this.$t('txt_deposit'),
          value: 'deposit',
        },
        {
          text: this.$t('txt_withdraw'),
          value: 'withdraw',
        },
      ],
      loading: false,
      loadingBank: false,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 1,
      },
      pagination_transaction: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 1,
      },
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      menu: false,
      flag_interval: null,
      bank: null,
      type: null,
    }
  },
  computed: {
    banks() {
      const bankKeys = this.$store.state.select.bankKey
      const result = Object.keys(bankKeys).map(key => ({
        name: bankKeys[key],
        value: key,
      }))
      result.unshift({
        name: this.$t('all'),
        value: null,
      });

      return result
    },
  },
  async created() {
    this.addLogPage()
    this.refreshdata()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) await this.getBank(newValue.page, newValue.itemsPerPage)
      },
      deep: true,
    },
    pagination_transaction: {
      async handler(newValue, oldValue) {
        this.pagination.rowsPerPage = newValue.itemsPerPage
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) await this.getTransactionBank(newValue.page, newValue.itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch("https://jsonip.com/").then(res => res.json().then(el => (
          data = {
            ip: el.ip,
            name: 'Logs ธนาคาร',
            url: window.location.href,
            detail: 'ระบบ Logs สถานะธนาคาร',
            admin: userSystem.name ? userSystem.name : '',
          }
        )))
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getBank(page, row) {
      this.loadingBank = true
      this.bankStatus = []
      const rs = await this.$store.dispatch('getAccountBank', {
        page,
        rows: row
      })
      this.pagination.totalItems = rs.count
      rs.data.forEach(element => {
        this.bankStatus.push({
          bank: element.bank,
          accName: element.bank_name,
          accNumber: element.bank_account,
          status: element.use ? true : false,
          use_withdraw: element.use_withdraw ? true : false,
          balance: element.balance,
          lastSuccessfulSynced: element.updated_at,
        })
      })
      this.loadingBank = false
    },

    async getTransactionBank(page, row, search) {
      if (page !== 1 || search) {
        clearInterval(this.flag_interval)
      }
      this.loading = true
      this.transactions = []
      if (this.$route.name === 'log-bank') {
        const rs = await this.$store.dispatch('getTransactionBanks', {
          ...(!search ? { page } : {}),
          ...(!search ? { rows: row } : {}),
          ...(search ? { search } : {}),
          ...(this.type ? { type: this.type } : {}),
          ...(this.bank ? { bank: this.bank } : {}),
          time_from: this.formatDateYYYY(this.dateFrom),
          time_to: this.formatDateYYYY(this.dateTo)
        })
        if (rs.count > 0) {
          this.pagination_transaction.totalItems = rs.count
          rs.data.forEach(element => {
            this.transactions.push({
              id: element.id,
              bank: element.bank,
              accName: element.bank_name,
              accNumber: element.bank_account,
              amount: element.amount,
              description: element.message,
              created_at: element.created_at,
              use_phone: element.member ? element.member.phone : '',
              amountStatus: element.type,
              timeday: moment(`${element.timeday}`).format('YY-MM-DD HH:mm'),
              time_change_status: element.time_change_status ? 'ฝาก(คงค้าง)' : 'AUTO'
            })
          })
        }
      } else {
        clearInterval(this.flag_interval)
      }
      this.loading = false
    },
    refreshdata() {
      this.flag_interval = setInterval(() => {
        this.getTransactionBank(1, this.pagination.itemsPerPage)
      }, 60000)
    },
    async find() {
      this.loading = true
      const params = `page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`
      const result = await this.getLogEditMember(params)
      this.pagination.totalItems = result.count
      this.logs = result.data
      this.loading = false
    },
    async findTransactionBank() {
      clearInterval(this.flag_interval)
      this.loading = true
      this.pagination_transaction.totalItems = 0
      let params = `page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`
      if (this.bank) {
        params = `${params}&bank=${this.bank}`
      }
      if (this.type) {
        params = `${params}&type=${this.type}`
      }
      this.transactions = []
      const rs = await this.$store.dispatch('getTransactionBanks', { params })
      if (rs.count > 0) {
        this.pagination_transaction.totalItems = rs.count
        rs.data.forEach(element => {
          this.transactions.push({
            id: element.id,
            bank: element.bank,
            accName: element.bank_name,
            accNumber: element.bank_account,
            amount: element.amount,
            description: element.message,
            created_at: element.created_at,
            use_phone: element.member ? element.member.phone : '',
            amountStatus: element.type,
            timeday: element.timeday,
          })
        })
      }
      this.pagination_transaction.page = 1
      this.loading = false
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
